<template>
  <main class="main-content"> 
    <!-- Top button container -->
    <div v-if="showTopNav" class="nav-button-container top">
      <button
        v-if="showPrevious"
        class="nav-button prev-button"
        @click="$emit('previous')"
      >
        {{ previousText }}
      </button>
      <button
        v-if="showNext"
        class="nav-button next-button"
        :class="{ active: allQuestionsAnswered }"
        @click="checkAnswers"
      >
        {{ nextText }}
      </button>
    </div>
    <p
      class="unanswered-error-message-top"
      :class="{ show: showErrorMessage }"
    >
      Please make the necessary selections.
    </p>    

    <slot /> 
    <p
      class="unanswered-error-message-bottom"
      :class="{ show: showErrorMessage }"
    >
      Please make the necessary selections.
    </p>
    <div class="nav-button-container">
      <button
        v-if="showPrevious"
        class="nav-button prev-button"
        @click="$emit('previous')"
      >
        {{ previousText }}
      </button>
      <button
        v-if="showNext"
        class="nav-button next-button"
        :class="{ active: allQuestionsAnswered }"
        @click="checkAnswers"
      >
        {{ nextText }}
      </button>
    </div>
    <!-- <progress-bar
      v-if="showProgressBar"
      :start="scrollStart"
      :end="scrollEnd"
    /> -->
  </main>
</template>

<script>
// import ProgressBar from './ProgressBar.vue';

export default {
  name: 'FormContainer',
  // components: { ProgressBar },
  props: {
    showPrevious: {
      type: Boolean,
      required: false,
      default: false,
    },
    showNext: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTopNav: { 
      type: Boolean, 
      default: false, 
    },
    previousText: {
      type: String,
      required: false,
      default: 'Back',
    },
    nextText: {
      type: String,
      required: false,
      default: 'Next',
    },
    allQuestionsAnswered: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollStart: {
      type: Number,
      required: false,
      default: 0,
    },
    scrollEnd: {
      type: Number,
      required: false,
      default: 100,
    },
    showProgressBar: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['previous', 'next'],
  data() {
    return {
      showErrorMessage: false,
    };
  },
  watch: {
    allQuestionsAnswered() {
      this.showErrorMessage = false;
    },
  },
  methods: {
    checkAnswers() {
      // This assumes that the number of answers should be equal to the number of questions
      if (this.allQuestionsAnswered) {
        this.showErrorMessage = false;
        this.$emit('next');
      } else {
        this.showErrorMessage = true;
      }
    },
  },
};

</script>

<style scoped>
.main-content {
  flex-grow: 1;
  background-color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 80%;
  margin: -50px auto 0 auto;
  padding: 0px 20px;
  /* Small shadow for depth */
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.unanswered-error-message-top {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  color: rgb(235, 55, 55);
  margin-left: auto;
  margin-right: 20px;
  margin-top: -20px;
  text-align: right;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.unanswered-error-message-top.show {
  visibility: visible;
  opacity: 1;
}

.unanswered-error-message-bottom {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  color: rgb(235, 55, 55);
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: -5px;
  text-align: right;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.unanswered-error-message-bottom.show {
  visibility: visible;
  opacity: 1;
}


.nav-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  min-width: 100px;
  margin: 10px 20px 30px auto;
}

.nav-button {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 1.2em;
  width: 30%;
  max-width: 400px;
  min-width: 100px;
  margin: 0px 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.next-button {
  background-color: #B5B5B5;
  color: white;
  /* margin: 0px 5px; */
}

.next-button.active {
  background-color: var(--activated-button-color);
}

.next-button:hover {
  background-color: #838383;  /* Slightly darker gray */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.next-button.active:hover {
  background-color: #03356b;  /* Slightly darker blue */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2); /* A slightly darker shadow for the active state */
}

.prev-button {
  background-color: #a47676;
  color: white;
  /* margin: 0px 5px; */
}

.prev-button:hover {
  background-color: #830505;  /* Slightly darker gray */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

</style>
